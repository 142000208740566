import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldJ: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull J?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_j.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull J?</h1>
          <h2>
            Not sure if pulling J is the right move? No worries! Check our
            review and guide for him!
          </h2>
          <p>
            Last updated: <strong>17/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="j"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          This one is a definite maybe, which is a nice respite from the slew of
          meta characters we received in 1.8 and Anniversary. J is good, don’t
          get us wrong, and he has a few very powerful and fun teams, but he
          isn’t going to deal the most damage, he isn’t going to hyperbuff your
          team nor is he going to make it unkillable
        </p>
        <SectionHeader title="Review" />
        <p>
          J is a 6-star Arcanist of the Beast Afflatus. Somewhere between Carry
          and Support, J's kit is focused around Counters and Burn, using both
          to deal consistent Mass DMG and apply sizable shields to the entire
          team.
        </p>

        <p>
          From his passives, J inflicts [Burn] on himself whenever he applies it
          to an enemy, gaining an amount of ATK based on the amount of stacks on
          himself and taking reduced damage from [Burn]. In addition, while
          affected by [Burn], J is immune to [Freeze]/[Cold] and while below 50%
          HP, he dispels all non [Burn] statuses from himself to gain Crit Rate
          at the start of the round.
        </p>
        <p>
          But how to apply [Burn]? That's done through "Heat Treating". This
          Incantation applies a 2 turn shield to all allies, it's size scaling
          with the amount of [Burn] stacks on J. Whenever enemies attack allies
          that carry J's shields, he counters with "Tempering", which is a Mass
          attack that deals Reality DMG scaling with J's Lost HP and inflicts
          [Burn].
        </p>
        <p>
          Then there's "Hardness Testing", which is a 1-target attack that
          inflicts [Burn] and grants allies a +DMG Dealt buff that scales with
          the amount of [Burn] stacks on J.
        </p>
        <p>
          Finally, "Over The Haight Street" is a Mass attack that inflicts
          [Burn] on enemies equal to the amount of [Burn] on J before it lands,
          with every excess [Burn] stack increasing the damage dealt of this
          attack.
        </p>
        <h5>Gameplay</h5>
        <p>
          Though he's not exactly punished for having [Burn], not reliably being
          able to be used with characters like 6 and Vila is a pretty steep
          price, as a majority of his kit revolves around either being low HP or
          having a lot of [Burn] applied to himself.
        </p>
        <p>
          Beyond that, there's only good stuff here. All of his Counters (which
          he can trigger individually per ally, even on Mass attacks from
          enemies) count as Extra Attacks, allowing him to fuel FUA based teams.
          In addition, his extremely consistent [Burn] application provides
          solid support for [Burn] and [Neg Status] based teams, and his damage
          scaling with Lost HP allows him to slot into HP Loss comps with
          Semmelweis, Kakania and shielders. Him providing his own Crit Rate and
          +DMG Dealt buffs makes him remarkably self-sufficient and his chunky
          2-turn shields also help provide additional survivability, though his
          immunity to [Freeze] is kind of unremarkable.
        </p>
        <p>
          In other words, he is a "niche" jack of all trades, focusing on
          dealing with aggressive enemies that cast a lot of incantations.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Incredibly high amount of FUAs per turn</li>
                  <li>
                    Highest Mass [Burn] application in the game (assuming his
                    enemies attack often)
                  </li>
                  <li>Strong shields with good duration</li>
                  <li>Massively benefits from the Euphoria system (2.3)</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Niche-oriented jack of all trades, doesn't properly fit
                    anywhere
                  </li>
                  <li>
                    Struggles with balancing damage and survivability depending
                    on the team
                  </li>
                  <li>
                    Cannot function properly with commons supports due to their
                    Cleanse (6, Vila, Tooth Fairy)
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>J works fine at P0.</p>
        <p>
          Depending on what you plan to use him for, you can either go for P1 or
          P2 if you want to improve his damage output or P3 if you want to
          include bigger shields.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          J provides ample synergies given his generalist kit. His large amounts
          of FUAs help units like 37 and Ms. NewBabel (Euphoria), his access to
          loads of [Burn] helps Spathodea, Isolde, Lopera and even Tuesday while
          his large shields (especially when paired with Ms. NewBabel) give him
          a way into HP Loss teams with Eternity (Euphoria) and Semmelweis.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="37"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="isolde"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="semmelweis"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="spathodea"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="tuesday"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lopera"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="ms-newbabel"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="eternity"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on J:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="the-unbearable-lightness"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="knock-on-the-door"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Teams" />
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Flaming Doritos
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="j"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="isolde"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="kakania"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>37</strong>
              </li>
              <li>
                Mass DMG oriented team for tough Limbos and Mass DMG fights like
                Isle Master, Bellows of the Earth or Projection of Nightmare.
                The idea is that J simultaneously helps Isolde stack [Heat]
                while also charging 37’s “Supplementary Formula”; once Isolde
                hits [Interlude], her constant FUAs will also help quicken 37’s
                [Eureka] generation. Kakania is here to provide some sustain
                while allowing J’s shields to take the mainstage in terms of
                survival so his HP is kept low to maximize the damage of
                “Tempering”.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Deathbed Warriors<span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="j"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="eternity"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="ms-newbabel"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Semmelweis</strong>
              </li>
              <li>
                The first proper HP Loss team comes to thee by the graces of the
                Euphoria system. With their Euphoria 1 unlocked, NewBabel can
                overcap J’s shields to massive sizes, ensuring that the team is
                kept safe regardless of the amount of HP they ultimately have;
                Eternity also turns into a potent Support, providing a slew of
                crit buffs scaling with HP Loss which is something Semmelweis
                does for DMG Bonus. This all ensures that J’s “Tempering” deals
                the most amount of damage, all while the team itself consumes
                very little AP due to Ms NewBabel and Semmelweis’ damage
                potential coming almost entirely from FUAs (just like J).
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldJ;
export const Head: React.FC = () => (
  <Seo
    title="Should you pull J? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling J is the right move? No worries! Check our review and guide for him!"
    game="reverse"
  />
);
